import React from 'react'

function About() {
    return (
        <div>
            {/* <h1 className='text-6xl mb-4'>Github Finder</h1> */}
            {/* <h1 className='mb-4 text-2xl font-light'>About This App</h1> */}
            <h1 className='mb-4 text-2xl font-light'>Github Finder</h1>
            <p className='text-lg text-gray-400'>App to search Github users</p>
            <p className='text-lg text-gray-400'>Version:<span className='text-white'> 1.0.0</span></p>
        </div>
    )
}

export default About
