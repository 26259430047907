
const githubReducer = (state, action) => {
    switch (action.type) {
        case 'GET_USERS':
            return {
                ...state,
                users: action.payload,
                loading: false
            }

        case 'GET_USER_AND_REPOS':

        console.log(action)

            return {
                ...state,
                user: action.payload.user,
                repos: action.payload.repos,
                loading: false
            }

        // case 'GET_USER':
        //     return {
        //         ...state,
        //         user: action.payload,
        //         loading: false
        //     }
        // case 'GET_REPOS':
        //     return {
        //         ...state,
        //         repos: action.payload,
        //         loading: false
        //     }

        case 'SET_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'CLEAR_USERS':
            return {
                ...state,
                users: []
            }

        default:
            return state
    }
}

// action is typically an object that has a type and the type is just a string
// state 就是原始的state， 各个东西的，初始状态, e.g., user and loading

export default githubReducer