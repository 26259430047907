import React from 'react'
import UserResults from '../componets/users/UserResults'
import UserSearch from '../componets/users/UserSearch'

function Home() {

    return (
        // 
        <div>
            <h1 className='text-6xl'>welcome xxx </h1>
            {/* {process.env.REACT_APP_GITHUB_TOKEN}
            {process.env.REACT_APP_GITHUB_URL} */}
          

            {/* Search componet */}
            <UserSearch />
            <UserResults />
        </div>
    )
}

export default Home
