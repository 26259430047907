// import { createContext, useState } from 'react'
import { createContext, useReducer } from 'react'
import githubReducer from './GithubReducer'

const GithubContext = createContext();

// const GITHUB_URL = process.env.REACT_APP_GITHUB_URL
// const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN

export const GithubProvider = ({ children }) => {
    // const [users, setUsers] = useState([])
    // const [loading, setLoading] = useState(true)

    const initialState = {
        users: [],
        user: {},
        repos: [],
        loading: false
    }

 

    // use reducer hook
    const [state, dispatch] = useReducer(githubReducer, initialState)

    //  Get initial users (testing purposes)
    // const fetchUsers = async () => {

    //     setLoading()

    //     const response = await fetch(`${GITHUB_URL}/users`, {
    //         headers: {
    //             Authorization: `token ${GITHUB_TOKEN}`,
    //         },
    //     })

    //     // console.log(process.env)

    //     const data = await response.json()

    //     // console.log(data)

    //     // setUsers(data)
    //     // setLoading(false)

    //     // dispatch = useState = setUsers + setLoading
    //     // in dispatch are action object and string
    //     dispatch({
    //         type: 'GT_USERS',
    //         payload: data

    //     })
    // }

    // Set loading

    // Clear users from state
    // const clearUsers = () => dispatch({ type: 'CLEAR_USERS' })

    // set loading
    // const setLoading = () => dispatch({
    //     type: 'SET_LOADING'
    // })

    return (
        <GithubContext.Provider
            value={{
                // users: state.users,
                // loading: state.loading,
                // user: state.user,
                // repos: state.repos,
                ...state,
                dispatch,
                // searchUsers,
                // clearUsers,
                // getUser,
                // getUserRepos
            }}
        >
            {children}
        </GithubContext.Provider>
    )
}

export default GithubContext